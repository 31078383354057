import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = (props) => {
    const location = useLocation()
    useEffect(() => {
      window.scrollTo({top:0, left: 0, behavior:"smooth"})
    }, [location])
    return (
        <div>{props.children}</div>
    )
}

export default ScrollTop