import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./AuthSlice";
import CartSlice from "./CartSlice";

const store = configureStore({
  reducer: {
    Auth: authSlice,
    Cart:CartSlice
  },
});

export default store;
