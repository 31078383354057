import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cartCount: 0
};

const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    updateCart(state, action) {
      state.cartCount = action.payload;
    },
  },
});

export const { updateCart } = cartSlice.actions;
export default cartSlice.reducer;
