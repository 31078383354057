import React from "react";

const Index = () => {
  return (
    <div className="page">
      <div className="header">
        <div className="policy-container">
          <p className="title">About</p>
        </div>
      </div>
      <div className="translations-content-container">
        <div className="policy-container">
          <div
            className="tab-content translations-content-item en visible"
            id="en"
          >
            <h1>About</h1>
            <p>GoviHub Ka Safar - Gadget Ki Kahaani</p>
            <p>
              Yo fam! Main hoon Govind, aur 2016 se chala raha hoon GoviHub ka
              show. Yeh koi regular tech dukaan nahi hai; yahaan pe hum killer
              deals pakadne ke liye hain, aur woh bhi especially iPhones par.
            </p>
            <p>
              GoviHub ka funda hai - digital life ko rocking banane ka, wallet
              ko thoda bhaari nahi padne dena. Har din, hum mehnat kar rahe hain
              taki hum tumhe laaye sabse latest tech wonders, aur sabse acha
              part? Hum vo budget-friendly banaye rakh rahe hain.
            </p>

            <p>
              Humare saath kyun chill karna chahiye? Kyunki hum sirf gadgets
              nahi bech rahe, hum quality stuff bech rahe hain jo teri savings
              ko vanish nahi hone denge. iPhones? Bilkul, aur vo bhi itne daam
              mein ki tu faint ho jaayega.
            </p>
            <p>
              Shoutout sabko jo GoviHub ke saath groove kar rahe hain, humein
              trust kar rahe hain apne tech partner banne ke liye. Get ready for
              a digital journey that's about to get a whole lot cooler.
            </p>
            <h3>GoviHub - Tera Swagwala Dost Digital Duniya Mein!</h3>
            <p>Yeh raha aapke GoviHub ke social media handles ka mention:</p>
            <div style={{ display: "flex" }}>
              <a
                href="https://www.instagram.com/govi_hub/"
                target="_blank"
                className="footer__social-link d-block"
              >
                <svg
                  className="svg-icon svg-icon_instagram instagram_icon"
               
                  viewBox="0 0 14 13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_instagram" />
                </svg>
              </a>

              <a
                href="https://youtube.com/@govihub"
                target="_blank"
                className="footer__social-link d-block"
              >
                <svg
                  className="svg-icon svg-icon_youtube"
                  width={50}
                  style={{color:"#FF0000"}}
                  height={45}
                  viewBox="0 0 14 13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_youtube" />
                </svg>
              </a>
            </div>

            <p>
              Best of luck with GoviHub's Instagram and YouTube ventures! If you
              need any more assistance or have other requests, feel
              free to let me know.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
