import React, { useState } from "react";
import user from "../../Services/user";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import { updateAuth } from "../../Redux/Store/AuthSlice";
const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("login");
  const [isVisible, setVisible] = useState(false);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };



  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.name) {
      errors["name"] = "Name can not be empty";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (!fields.mobile) {
      errors["mobile"] = "Mobile can not be empty";
      formIsValid = false;
    }
    // if (!file) {
    //   errors["file"] = "Image can not be empty";
    //   formIsValid = false;
    // }

    setErrors(errors);
    return formIsValid;
  };
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let params = fields;
    user
      .login(params)
      .then((data) => {
        let res = data.data;
        if (res.status) {
          setLoader(false);
          localStorage.setItem("access_token", res?.data?.access_token);
          localStorage.setItem("name", res?.data?.user_data?.name);
          localStorage.setItem("mobile", res?.data?.user_data?.mobile);
          localStorage.setItem("email", res?.data?.user_data?.email);
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem(
            "user_id",
            JSON.stringify(res.data?.user_data.id)
          );
          localStorage.setItem("admin", res?.data?.data);
          let admin = {
            access_token: res?.data?.access_token,
            user_id: res?.data?.user_data?.id,
            email: res?.data?.user_data?.email,
            name: res?.data?.user_data?.name,
            mobile: res?.data?.user_data?.mobile,
          };
          dispatch(updateAuth(admin));
          setLoader(false);
          if (res?.data?.access_token) {
            navigate("/", { replace: true });
          }
        } else {
          toast.error(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          let errors = {};
          for (let key in res.message) {
            errors[key] = res.message[key];
          }
          setErrors(errors);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setIsLoader(true);
    let params = fields;
    user
      .signup(params)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoader(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFields({});
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          let errors = {};
          for (let key in res.message) {
            errors[key] = res.message[key];
          }
          setErrors(errors);
          setIsLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoader(false);
      });
  };

  return (
    <main>
      <div className="mb-4 pb-4" />
      <section className="login-register container">
        <h2 className="d-none">Login &amp; Register</h2>
        <Nav
          tabs
          className="nav nav-tabs mb-5"
          id="login_register"
          role="tablist"
        >
          <NavItem className="nav-item" role="presentation">
            <NavLink
              className={`nav-link nav-link_underscore ${
                activeTab === "login" ? "active" : ""
              }`}
              onClick={() => toggleTab("login")}
            >
              Login
            </NavLink>
          </NavItem>
          <NavItem className="nav-item" role="presentation">
            <NavLink
              className={`nav-link nav-link_underscore ${
                activeTab === "register" ? "active" : ""
              }`}
              onClick={() => toggleTab("register")}
            >
              Register
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          className="tab-content pt-2"
          id="login_register_tab_content"
        >
          <TabPane tabId="login">
            <div
              className="tab-pane  show active"
              role="tabpanel"
              aria-labelledby="login-tab"
            >
              <div className="login-form signup_form">
                <form
                  name="login-form"
                  className="needs-validation"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="mb-3">
                    <span className="text-secondary">Email:</span>
                    <input
                      name="login_email"
                      type="email"
                      className="form-control form-control_gray"
                      id="customerNameEmailInput1"
                      placeholder="Email address *"
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                      required
                    />
                  </div>
                  <div className="pb-3" />
                  <div className="mb-3">
                    <span className="text-secondary">Password: 
                    </span>

                    <input
                      name="login_password"
                      type="password"
                      className="form-control form-control_gray"
                      id="customerPasswodInput"
                      placeholder="Password *"
                      value={fields["password"] ? fields["password"] : ""}
                      onChange={(event) => handleChange(event, "password")}
                      required
                    />
                    
                    
                  </div>

                  <button
                    className="btn btn-primary w-100 text-uppercase"
                    disabled={loader}
                    type="submit"
                  >
                    {loader ? <Spinner size="sm" className="mx-1" /> : ""}
                    Log In
                  </button>
                </form>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="register">
            <div
              className="tab-pane "
              role="tabpanel"
              aria-labelledby="register-tab"
            >
              <div className="register-form signup_form">
                <form
                  name="register-form"
                  className="needs-validation"
                  onSubmit={handleSignup}
                >
                  <div className="mb-3">
                    <span className="text-secondary">Name:</span>

                    <input
                      name="name"
                      type="text"
                      className="form-control form-control_gray"
                      id="customerNameRegisterInput"
                      placeholder="Username"
                      value={fields["name"] ? fields["name"] : ""}
                      onChange={(event) => handleChange(event, "name")}
                      required
                    />
                  </div>
                  <div className="pb-3" />
                  <div className="mb-3">
                    <span className="text-secondary">Email:</span>

                    <input
                      name="email"
                      type="email"
                      className="form-control form-control_gray"
                      id="customerEmailRegisterInput1"
                      placeholder="Email address *"
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                      required
                    />
                  </div>
                  <div className="pb-3" />
                  <div className="mb-3">
                    <span className="text-secondary">Mobile:</span>

                    <input
                      name="mobile"
                      type="text"
                      className="form-control form-control_gray"
                      id="customerEmailRegisterInput2"
                      placeholder="Mobile Number *"
                      value={fields["mobile"] ? fields["mobile"] : ""}
                      onChange={(event) => handleChange(event, "mobile")}
                      required
                    />
                  </div>
                  <div className="pb-3" />
                  <div className="mb-3">
                    <span className="text-secondary">Password:</span>

                    <input
                      name="password"
                      type="password"
                      className="form-control form-control_gray"
                      id="customerPasswodRegisterInput"
                      placeholder="Password *"
                      value={fields["password"] ? fields["password"] : ""}
                      onChange={(event) => handleChange(event, "password")}
                      required
                    />
                  </div>

                  <button
                    className="btn btn-primary w-100 text-uppercase"
                    disabled={isloader}
                    type="submit"
                  >
                    {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
                    Register
                  </button>
                </form>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </section>
    </main>
  );
};

export default Index;
