
import { updateCart } from '../Redux/Store/CartSlice'; // Assuming updateCart is an action creator defined in your Redux slice
import home from "../Services/home"

export const getCounts = () => async (dispatch) => {
  try {
    const res = await home.cartCounts();
    if (res?.data?.status) {
      dispatch(updateCart(res?.data?.data?.cart_count));
    }
  } catch (error) {
    console.error("Error fetching cart counts:", error);
  }
};
