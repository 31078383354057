import React from "react";

const Index = () => {
  return (
    <div className="page">
      <div className="header">
        <div className="policy-container">
          <p className="title"> SHIPPING &amp; DELIVERY POLICY</p>
        </div>
      </div>
      <div className="translations-content-container">
        <div className="policy-container">
          <div
            className="tab-content translations-content-item en visible"
            id="en"
          >
            <p>Last updated: January 30, 2024</p>
            <p>
              Please carefully review our Shipping &amp; Delivery Policy when
              purchasing our products. This policy will apply to any order you
              place with us.
            </p>

            <h3>WHAT ARE MY SHIPPING &amp; DELIVERY OPTIONS?</h3>
            <p>In-Store Pickup</p>
            <ul>
              <li>
                <strong>In-store</strong>
                <p>
                  In store pickup is available for___________ Pickups are
                  available______________
                </p>
              </li>
              <li>
                <strong>Free Shipping</strong>
                <p>
                  {" "}
                  We offer free___________ shipping on
                  ______________for__________
                </p>
              </li>
              <li>
                <strong>Expedited Shipping Fees</strong>
                <p>We also offer expedited shipping at the following rates:</p>
                <p>____________ With in 24 hours</p>
                <p> 500 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________</p>
              </li>
              <li>
                <p>
                  If you select an expedited shipping option, we will follow up
                  after you have placed the order with any additional shipping
                  information.
                </p>
              </li>
              <li>
                <p>
                  All times and dates given for delivery of the products are
                  given in good faith but are estimates only.
                </p>
              </li>
            </ul>
            <h2>DO YOU DELIVER INTERNATIONALLY?</h2>

            <ul>
              <li>
                <p> We do not offer international shipping.</p>
              </li>
            </ul>
            <h4> HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
            <p>
              {" "}
              If you have any further questions or comments, you may contact us
              by:
            </p>

            <ul>
              <li>Phone: 8377995000</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
