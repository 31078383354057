import React, { useState, useEffect } from "react";
import cart from "../../Services/cart";
import common from "../../Services/common";
import { toast } from "react-toastify";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { getCounts } from "../../common/ReduxApi";
import { useDispatch } from "react-redux";
import Select from "react-select";
const Index = () => {
  const [shoplist, setShopList] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isTab2Enabled, setIsTab2Enabled] = useState(false);
  const [isTab3Enabled, setIsTab3Enabled] = useState(false);
  const [fields, setFields] = useState({ payment_type: "cod" });
  const [errors, setErrors] = useState({});
  const [isloader, setIsLoader] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState([]);
  const [onlineData,setOnlineData] = useState("")
  const [loader, setLoader] = useState(false);
  const [reFetch, setreFetch] = useState(false);
  const today = moment();
  const dispatch = useDispatch();
  const query = new URLSearchParams(
    window.location.search.replace(/\+/g, "%2B")
  );
  const udata = query.get("udata");
  

  const toggleTab = (tab) => {
    if (tab === "1" || tab === "2" || tab === "3") {
      setActiveTab(tab);
    }
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleStateChange = (value) => {
    fields["state_id"] = value;
    setFields(fields);
    setErrors({ ...errors, ["state_id"]: "" });
  };
  const handleCountryChange = (value) => {
    fields["country_id"] = value;
    setFields(fields);
    setErrors({ ...errors, ["country_id"]: "" });
  };

  const handleCouponSubmit = () => {
    // e.preventDefault();

    let params = {
      coupon_code: fields.coupon_code,
    };

    setIsLoading(true);
    cart
      .list(params)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);

          setShopList(res?.data?.data?.cart);
          setAppliedCouponCode(res?.data?.data?.coupons);
        }
      })
      .catch(function (error) {
        setIsLoading(false);

        console.log("error", error);
      });
  };

  const deleteProduct = async (id) => {
    const params = {
      product_id: id,
    };

    try {
      const res = await cart.delete(params);
      if (res?.data?.status) {
        const updatedShopList = shoplist.Cart_items.filter(
          (item) => item.Product.id !== id
        );
        setShopList({ ...shoplist, Cart_items: updatedShopList });
        await dispatch(getCounts());
        setreFetch(!reFetch);
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const getStates = () => {
    cart
      .state_list()
      .then((res) => {
      
        if (res?.data?.status) {

          let states = [];
          res?.data?.data?.states?.forEach((state, index) => {
           
            states[index] = {
              label: state?.name,
              value: state?.id,
            };
          });
         
          setStates(states);

          // setStates(res?.data?.data?.states);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const getCountry = () => {
    cart
      .country_list()
      .then((res) => {
        if (res?.data?.status) {
          let countries = [];
          res?.data?.data?.countries?.forEach((state, index) => {
           
            countries[index] = {
              label: state?.name,
              value: state?.id,
            };
          });

          setCountries(countries);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
  
    let params = {
      cart_id: shoplist?.id,
      payment_mode: fields.payment_type,
      coupon_code: shoplist?.cart_traffic?.coupon_code,
      address: {
        name: fields.name,
        email: fields.email,
        mobile: fields.mobile,
        pincode: fields.pincode,
        city: fields.city,
        state_id: fields.state_id?.value,
        country_id: fields.country_id?.value,
      },
    };
  
    try {
      const res = await (fields.payment_type === "online" ? cart.order_add(params) : cart.add_order(params));
      if (res?.data?.status) {
        await dispatch(getCounts());
        setIsLoader(false);
        setOrderId(res?.data?.data?.order_id);
       
       
        
        

        if (fields.payment_type === "online" && res?.data?.data?.instrumentResponse?.redirectInfo?.url) {
          window.history.pushState({}, '', '/shop-cart'); // Modify browser history
          window.location.href = res?.data?.data?.instrumentResponse?.redirectInfo?.url;
        }
        else{
          toggleTab("3");
          setIsTab3Enabled(true);
        }



        // toast.success(res.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoader(false);
      }
    } catch (error) {
      console.error("Error submitting order:", error);
      toast.error("An error occurred while submitting the order", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoader(false);
    }
  };
  

  const handleItem = (id, action) => {
    let params = {
      product_id: id,
      type: action,
    };
    setLoader(true);
    cart
      .number_add(params)
      .then((res) => {
        if (res?.data?.status) {
          handleCouponSubmit();
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // window.location.reload();
          setLoader(false);
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getStates();
    getCountry();
    handleCouponSubmit();
  }, [reFetch]);

  return (
    <main>
      {isloading ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />
        </div>
      ) : (
        <>
          <div className="mb-4 pb-4" />
          {shoplist == null ? (
            <div className="d-flex justify-content-center align-items-center  flex-column">
              <img src="/assets/images/shop-cart.png" alt="govihub-image" />

              <p>There are no item in your cart</p>
            </div>
          ) : (
            <section className="shop-checkout container">
              <h2 className="page-title">Cart</h2>
              <Nav tabs>
                {activeTab !== "3" && (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => toggleTab("1")}
                      >
                        <span className="checkout-steps__item-number">01</span>
                        <span className="checkout-steps__item-title">
                          <span>Shopping Bag</span>
                          <em>Manage Your Items List</em>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2" && isTab2Enabled,
                          disabled: !isTab2Enabled,
                        })}
                        onClick={() => toggleTab("2")}
                      >
                        <span className="checkout-steps__item-number">02</span>
                        <span className="checkout-steps__item-title">
                          <span>Shipping and Checkout</span>
                          <em>Checkout Your Items List</em>
                        </span>
                      </NavLink>
                    </NavItem>
                  </>
                )}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3" && isTab3Enabled,
                      disabled: !isTab3Enabled,
                    })}
                    onClick={() => toggleTab("3")}
                  >
                    <span className="checkout-steps__item-number">03</span>
                    <span className="checkout-steps__item-title">
                      <span>Confirmation</span>
                      <em>Review And Submit Your Order</em>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="shopping-cart">
                    <div className="cart-table__wrapper">
                      <table className="cart-table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th />
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Subtotal</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {shoplist?.Cart_items?.map((shop, i) => (
                            <tr key={i}>
                              <td>
                                <div className="shopping-cart__product-item">
                                  <img
                                    loading="lazy"
                                    // src="/assets/images/cart-item-1.jpg"
                                    src={
                                      shop?.Product?.Product_images[0]?.image
                                    }
                                    width={120}
                                    height={120}
                                    alt
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="shopping-cart__product-item__detail">
                                  <h4>{shop?.Product?.name}</h4>
                                  <ul className="shopping-cart__product-item__options">
                                    <li>
                                      {" "}
                                      {
                                        shop?.Product?.Product_categories[0]
                                          ?.Category?.name
                                      }
                                    </li>
                                    {/* <li>Size: L</li> */}
                                  </ul>
                                </div>
                              </td>
                              <td>
                                <span className="shopping-cart__product-price">
                                  {" "}
                                  {common?.FormatAmount(shop?.Product?.price)}
                                </span>
                              </td>
                              <td>
                                <div className="qty-control position-relative">
                                  <input
                                    type="number"
                                    name="quantity"
                                    defaultValue={shop?.qty}
                                    min={1}
                                    className="qty-control__number text-center"
                                  />
                                  <div
                                    className="qty-control__reduce"
                                    onClick={() =>
                                      handleItem(shop?.product_id, "minus")
                                    }
                                  >
                                    -
                                  </div>
                                  <div
                                    className="qty-control__increase"
                                    onClick={() =>
                                      handleItem(shop?.product_id, "plus")
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                                {/* .qty-control */}
                              </td>
                              <td>
                                <span className="shopping-cart__subtotal">
                                  &nbsp;
                                  {common?.FormatAmount(shop?.Product?.price)}
                                </span>
                              </td>
                              <td
                                onClick={() => deleteProduct(shop?.Product?.id)}
                              >
                                <a href="#" className="remove-cart">
                                  <svg
                                    width={10}
                                    height={10}
                                    viewBox="0 0 10 10"
                                    fill="#767676"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M0.259435 8.85506L9.11449 0L10 0.885506L1.14494 9.74056L0.259435 8.85506Z" />
                                    <path d="M0.885506 0.0889838L9.74057 8.94404L8.85506 9.82955L0 0.97449L0.885506 0.0889838Z" />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="cart-table-footer d-flex">
                        <form className="position-relative bg-body">
                          <input
                            className="form-control"
                            type="text"
                            name="coupon_code"
                            value={
                              fields["coupon_code"] ? fields["coupon_code"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "coupon_code")
                            }
                            placeholder="Coupon Code"
                          />
                        </form>
                        <button
                          className="btn btn-light"
                          onClick={handleCouponSubmit}
                        >
                          Apply
                        </button>
                        {shoplist?.cart_traffic?.coupon_code &&
                        shoplist?.cart_traffic?.coupon_applied ? (
                          <p className="text-success">
                            {shoplist?.cart_traffic?.coupon_message}
                          </p>
                        ) : (
                          <p className="text-danger">
                            {shoplist?.cart_traffic?.coupon_message}
                          </p>
                        )}
                      </div>
                      {/* <div className="mt-3">
                        <strong>Coupons</strong>
                        {appliedCouponCode?.map((ele, i) => (
                          <div
                            class="d-flex justify-content-center p-1 mb-1 responsive-class"
                            style={{
                              backgroundColor: "rgb(234 241 247)",
                              width: "35%",
                              borderRadius: "5px",
                            }}
                          >
                            <span className="mx-4">{ele?.code}</span>
                            {ele?.type === "discount"
                              ? `${ele?.discount}% discount`
                              : `₹${ele?.discount} discount`}
                          </div>
                        ))}
                      </div> */}
                    </div>
                    <div className="shopping-cart__totals-wrapper">
                      <div className="sticky-content">
                        <div className="shopping-cart__totals">
                          <h3>Cart Totals</h3>
                          <table className="cart-totals">
                            <tbody>
                              <tr>
                                <th>Subtotal</th>
                                <td>
                                  {common?.FormatAmount(
                                    shoplist?.cart_traffic?.sub_total
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Shipping</th>
                                <td>
                                  <div>
                                    <label
                                      className="form-check-label"
                                      htmlFor="free_shipping"
                                    >
                                     ₹{ shoplist?.cart_traffic?.shipping_charge}
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              {shoplist?.cart_traffic?.coupon_applied ==
                                true && (
                                <tr>
                                  <th>Discount</th>
                                  <td>
                                    <div>
                                      <label
                                        className="form-check-label"
                                        htmlFor="free_shipping"
                                      >
                                        -
                                        {common?.FormatAmount(
                                          shoplist?.cart_traffic
                                            ?.discount_amount
                                        )}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* <tr>
                                <th>GST</th>
                                <td>
                                  {common?.FormatAmount(
                                    shoplist?.cart_traffic?.gst
                                  )}
                                </td>
                              </tr> */}
                              <tr>
                                <th>Total</th>
                                <td>
                                  {common?.FormatAmount(
                                    shoplist?.cart_traffic?.total
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mobile_fixed-btn_wrapper">
                          <div className="button-wrapper container">
                            <button
                              className="btn btn-primary btn-checkout"
                              onClick={() => {
                                setIsTab2Enabled(true);
                                toggleTab("2");
                              }}
                            >
                              {isloader ? (
                                <Spinner size="sm" className="mx-1" />
                              ) : (
                                ""
                              )}
                              PROCEED TO CHECKOUT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <form name="checkout-form" onSubmit={handleSubmit}>
                    <div className="checkout-form">
                      <div className="billing-info__wrapper">
                        <h4>BILLING DETAILS</h4>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="my-3">
                              <span>Name</span>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={fields["name"] ? fields["name"] : ""}
                                onChange={(event) =>
                                  handleChange(event, "name")
                                }
                                placeholder="Name"
                              />
                              {/* <label htmlFor="name">Name</label> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="my-3">
                              <span>Email</span>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={fields["email"] ? fields["email"] : ""}
                                onChange={(event) =>
                                  handleChange(event, "email")
                                }
                                placeholder="Email"
                              />
                              {/* <label htmlFor="email">Email</label> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="my-3">
                              <span>Mobile</span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Mobile"
                                name="mobile"
                                value={fields["mobile"] ? fields["mobile"] : ""}
                                onChange={(event) =>
                                  handleChange(event, "mobile")
                                }
                              />
                              {/* <label htmlFor="mobile">Mobile</label> */}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="my-3">
                              <span>Pincode</span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pincode"
                                name="pincode"
                                value={
                                  fields["pincode"] ? fields["pincode"] : ""
                                }
                                onChange={(event) =>
                                  handleChange(event, "pincode")
                                }
                              />
                              {/* <label htmlFor="pincode">Pincode</label> */}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="my-3">
                              <span>City</span>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={fields["city"] ? fields["city"] : ""}
                                onChange={(event) =>
                                  handleChange(event, "city")
                                }
                                placeholder="City"
                              />
                              {/* <label htmlFor="city">City</label> */}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="search-field my-3">
                              <span>State</span>
                              <div className="form-label-fixed hover-container">
                              <Select
                      name="state_id"
                      id="state_id"
                      placeholder={<div>Select State...</div>}
                      value={fields["state_id"] ? fields["state_id"] : []}
                      options={states}
                      classNamePrefix="select"
                      onChange={handleStateChange}
                    />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="search-field my-3">
                              <span>Country</span>
                              <div className="form-label-fixed hover-container">
                              <Select
                      name="country_id"
                      id="country_id"
                      placeholder={<div>Select Country...</div>}
                      value={fields["country_id"] ? fields["country_id"] : []}
                      options={countries}
                      classNamePrefix="select"
                      onChange={handleCountryChange}
                    />
                              </div>
                            </div>
                          </div>
                          <div>
                            <Row className="mt-4">
                              <h4>PAYMENT MODE</h4>
                              <Col md={12} className="d-flex form_checkbox">
                              <div class="form-check pay_radio2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="payment_type"
                                    id="payment_type_clinic"
                                    value="cod"
                                    checked={fields.payment_type == "cod"}
                                    onChange={(event) =>
                                      handleChange(event, "payment_type")
                                    }
                                  />
                                  <label
                                    class="form-check-label inclinic_radio"
                                    for="payment_type_clinic"
                                  >
                                    COD  &nbsp; &nbsp;
                                  </label>
                                </div>

                                <div class="form-check pay_radio">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="payment_type"
                                    id="payment_type_online"
                                    value="online"
                                    checked={fields.payment_type == "online"}
                                    // disabled
                                    onChange={(event) =>
                                      handleChange(event, "payment_type")
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="payment_type_online"
                                  >
                                    Online
                                  </label>
                                 
                                </div>
                             
                              </Col>
                            </Row>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary btn-checkout mt-5"
                          >
                            {isloader ? (
                              <Spinner size="sm" className="mx-1" />
                            ) : (
                              ""
                            )}
                            PLACE ORDER
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </TabPane>
                <TabPane tabId="3">
                  <div className="order-complete">
                    <div className="order-complete__message">
                      <svg
                        width={80}
                        height={80}
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx={40} cy={40} r={40} fill="#B9A16B" />
                        <path
                          d="M52.9743 35.7612C52.9743 35.3426 52.8069 34.9241 52.5056 34.6228L50.2288 32.346C49.9275 32.0446 49.5089 31.8772 49.0904 31.8772C48.6719 31.8772 48.2533 32.0446 47.952 32.346L36.9699 43.3449L32.048 38.4062C31.7467 38.1049 31.3281 37.9375 30.9096 37.9375C30.4911 37.9375 30.0725 38.1049 29.7712 38.4062L27.4944 40.683C27.1931 40.9844 27.0257 41.4029 27.0257 41.8214C27.0257 42.24 27.1931 42.6585 27.4944 42.9598L33.5547 49.0201L35.8315 51.2969C36.1328 51.5982 36.5513 51.7656 36.9699 51.7656C37.3884 51.7656 37.8069 51.5982 38.1083 51.2969L40.385 49.0201L52.5056 36.8996C52.8069 36.5982 52.9743 36.1797 52.9743 35.7612Z"
                          fill="white"
                        />
                      </svg>
                      <h3>Your order is completed!</h3>
                      <p>Thank you. Your order has been received.</p>
                    </div>
                    <div className="order-info">
                      <div className="order-info__item">
                        <label>Order Number</label>
                        <span>#{orderId}</span>
                      </div>
                      <div className="order-info__item">
                        <label>Date</label>
                        <span>{today.format("YYYY-MM-DD")}</span>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </section>
          )}
        </>
      )}
    </main>
  );
};

export default Index;
