
import Home from "../Views/Home";
import Products from "../Views/Products"
import ProductDetails from "../Views/ProductDetails"
import ShopCart from "../Views/ShopCart"
import Login from "../Views/Login"
import About from "../Views/About"
import PrivacyPolicy from "../Views/PrivacyPolicy"
import RefundPolicy from "../Views/RefundPolicy"
import TermsCondition from "../Views/TermsCondition"
import ShippingPolicy from "../Views/ShippingPolicy"
import Account from "../Views/Account"
import Contact from "../Views/Contact"
import OrderPlaced from "../Views/ShopCart/OrderPlaced";


const Index = [
  {
    path: "/",
    name: "Home",
    element: Home,
    authentication: false,
  },
  {
    path: "products",
    name: "Products",
    element: Products,
    authentication: false,
  },                           
  {
    path: "contact",
    name: "Contacts",
    element: Contact,
    authentication: false,
  },                           
  {
    path: "my-account",
    name: "My Account",
    element: Account,
    authentication: true,
  },                           
 
  {
    path: "product-details",
    name: "Product Details",
    element: ProductDetails,
    authentication: false,
  },
  {
    path: "shop-cart",
    name: "Shop Cart",
    element: ShopCart,
    authentication: true,
  },
  {
    path: "order-placed",
    name: "Order Placed",
    element: OrderPlaced,
    authentication: true,
  },
  {
    path: "privacy-policy",
    name: "Privacy Policy",
    element: PrivacyPolicy,
    authentication: false,
  },
  {
    path: "return-and-refund-policy",
    name: "Refund Policy",
    element: RefundPolicy,
    authentication: false,

  },
  {
    path: "terms-and-conditions",
    name: "Terms Conditions",
    element: TermsCondition,
    authentication: false,

  },
  {
    path: "shipping-policy",
    name: "Shipping Policy",
    element: ShippingPolicy,
    authentication: false,

  },
  {
    path: "login",
    name: "Login",
    element: Login,
    authentication: false,

  },
  {
    path: "about",
    name: "About",
    element: About,
    authentication: false,

  },
 
];

export default Index;
