const common = {
    FormatAmount: (amount) => {
      let formattedAmount = Number(amount || 0)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      formattedAmount = "₹" + formattedAmount;
      return formattedAmount;
    },
  
}  

export default common;
