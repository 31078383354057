import React, { Suspense, useState,useEffect } from "react";
import "./css/style.css";
import "./css/plugins/swiper.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/_main.scss";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import FrontRoutes from "./Routes";
import FrontLayout from "./Layouts";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import ScrollToTop from "./common/ScrollTop";
import home from "./Services/home";
import { updateCart } from "./Redux/Store/CartSlice";
const Home = React.lazy(() => import("./Views/Home"));

// const useCartCounts = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const getCounts = async () => {
//       try {
//         const res = await home.cartCounts();
//         if (res?.data?.status) {
//           dispatch(updateCart(res?.data?.data?.cart_count));
//         }
//       } catch (error) {
//         console.error("Error fetching cart counts:", error);
//       }
//     };

//     getCounts();
//   }, [dispatch]);

//   return null; // or you can return any value you need
// };
function App() {
  const [color, setColor] = useState("red");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const access_token = useSelector((state) => state?.Auth[0]?.access_token);
  const checkAuth = (route) => {
    if (route.authentication) {
      if (access_token === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };





  return (
    <BrowserRouter>
      <ScrollToTop>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="vh-100 d-flex align-items-center justify-content-center">
            <img src="/assets/images/logo-blue.png" alt="Govihub-logo" />
            <Spinner
              color="primary"
              style={{
                height: "3rem",
                width: "3rem",
              }}
            />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<FrontLayout />}>
            {FrontRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  checkAuth(route) ? (
                    <route.element />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
