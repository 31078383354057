import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/*------------ Footer Type 2 ------------*/}
      <footer id="footer" className="footer footer_type_2 bordered">
  
        {/* /.footer-top container */}
        <div className="footer-middle container">
          <div className="row row-cols-lg-5 row-cols-2">
            <div className="footer-column footer-store-info col-12 mb-4 mb-lg-0">
              <div className="logo">
                <Link to="https://uomo-html.flexkitux.com/">
                  <img
                    src="/assets/images/logo-blue.png"
                    alt="Uomo"
                    className="logo__image d-block"
                  />
                </Link>
              </div>
              {/* /.logo */}
              <p className="footer-address">
              D-105, Thana Rd, Block D, Bhajanpura, Shahdara, Delhi, 110053
              </p>
              <p className="m-0">
                <strong className="fw-medium">Govihubb@gmail.com</strong>
              </p>
              <p>
                <strong className="fw-medium">+91 8377995000</strong>
              </p>
            </div>
            {/* /.footer-column */}
            <div className="footer-column footer-menu mb-4 mb-lg-0">
              <h6 className="sub-menu__title text-uppercase">Company</h6>
              <ul className="sub-menu__list list-unstyled">
                <li className="sub-menu__item">
                  <Link to="/about" className="menu-link menu-link_us-s">
                    About Us
                  </Link>
                </li>

                <li className="sub-menu__item">
                  <Link to="/contact" className="menu-link menu-link_us-s">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* /.footer-column */}
            <div className="footer-column footer-menu mb-4 mb-lg-0">
              <h6 className="sub-menu__title text-uppercase">Help</h6>
              <ul className="sub-menu__list list-unstyled">
                <li className="sub-menu__item">
                  <Link
                    to="/my-account"
                    className="menu-link menu-link_us-s"
                  >
                    My Account
                  </Link>
                </li>

                <li className="sub-menu__item">
                  <Link
                    to="/privacy-policy"
                    className="menu-link menu-link_us-s"
                  >
                    Privacy &amp; Privacy
                  </Link>
                </li>
                <li className="sub-menu__item">
                  <Link
                    to="/return-and-refund-policy"
                    className="menu-link menu-link_us-s"
                  >
                    Return and Refund Policy
                  </Link>
                </li>

                <li className="sub-menu__item">
                  <Link
                    to="/terms-and-conditions"
                    className="menu-link menu-link_us-s"
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li className="sub-menu__item">
                  <Link
                    to="/shipping-policy"
                    className="menu-link menu-link_us-s"
                  >
                   Shipping Policy
                  </Link>
                </li>
                {/* <li className="sub-menu__item">
                  <Link to="contact.html" className="menu-link menu-link_us-s">
                    Contact
                  </Link>
                </li> */}
              </ul>
            </div>
            {/* /.footer-column */}
            <div className="footer-column mb-4 mb-lg-0">
              <h6 className="sub-menu__title text-uppercase">Follow us</h6>
              <ul className="social-links list-unstyled d-flex flex-wrap mb-0">
                <li>
                <a
                    href="https://www.facebook.com/govihubofficial"
                    target="_blank"
                  className="footer__social-link d-block">
                    <svg
                      class="svg-icon svg-icon_facebook"
                      width="9"
                      height="15"
                      viewBox="0 0 9 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_facebook"></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtube.com/@govihub"
                    target="_blank"
                    className="footer__social-link d-block"
                  >
                    <svg
                      className="svg-icon svg-icon_youtube"
                      width={14}
                      height={13}
                      viewBox="0 0 14 13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_youtube" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/govi_hub"
                    target="_blank"
                    className="footer__social-link d-block"
                  >
                    <svg
                      className="svg-icon svg-icon_instagram"
                      width={14}
                      height={13}
                      viewBox="0 0 14 13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_instagram" />
                    </svg>
                  </a>
                </li>
                
              </ul>
            </div>
            {/* /.footer-column */}
          </div>
          {/* /.row-cols-5 */}
        </div>
        {/* /.footer-middle container */}
        <div className="footer-bottom">
          <div className="container d-md-flex align-items-center">
            <span className="footer-copyright me-auto">©2023 Govihub</span>

            {/* /.footer-settings */}
          </div>
          {/* /.container d-flex align-items-center */}
        </div>
        {/* /.footer-bottom container */}
      </footer>
      {/*------------ Mobile Fixed Footer ------------*/}
      <footer className="footer-mobile container w-100 px-5 d-md-none bg-body">
        <div className="row text-center">
          <div className="col-4">
            <Link
              to="https://uomo-html.flexkitux.com/"
              className="footer-mobile__link d-flex flex-column align-items-center"
            >
              <svg
                className="d-block"
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use to="#icon_home" />
              </svg>
              <span>Home</span>
            </Link>
          </div>
          {/* /.col-3 */}
          <div className="col-4">
            <Link
              to="https://uomo-html.flexkitux.com/"
              className="footer-mobile__link d-flex flex-column align-items-center"
            >
              <svg
                className="d-block"
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use to="#icon_hanger" />
              </svg>
              <span>Shop</span>
            </Link>
          </div>
          {/* /.col-3 */}
          <div className="col-4">
            <Link
              to="https://uomo-html.flexkitux.com/"
              className="footer-mobile__link d-flex flex-column align-items-center"
            >
              <div className="position-relative">
                <svg
                  className="d-block"
                  width={18}
                  height={18}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use to="#icon_heart" />
                </svg>
                <span className="wishlist-amount d-block position-absolute js-wishlist-count">
                  3
                </span>
              </div>
              <span>Wishlist</span>
            </Link>
          </div>
          {/* /.col-3 */}
        </div>
        {/* /.row */}
      </footer>
      {/* /.footer-mobile container position-fixed d-md-none bottom-0 */}
    </div>
  );
};

export default Footer;
