import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import cart from "../../Services/cart";

const AccountDetails = (props) => {
  const [orders, setOrders] = useState({});
  const [loader, setLoader] = useState(false);

  const getUser = (id) => {
    let params = id;
    setLoader(true);
    cart
      .getOne(params)
      .then((res) => {
        if (res?.data?.status) {
          setOrders(res?.data?.data?.order);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (props?.accountId) {
      getUser(props?.accountId);
    }
  }, []);

  return (
    <Modal isOpen={props?.accountOpen} size="lg" className="details_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        {`Order Id'${orders?.id}`}
      </ModalHeader>
      <ModalBody className="pl-4 pr-4">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Price</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {orders?.Order_items?.map((ele, i) => (
              <tr>
                <td>
                  {" "}
                  <img
                    src={ele?.Order_product?.Product_images[0]?.image}
                    style={{ height: "50px" }}
                  />
                </td>
                <td> {ele?.Order_product?.name}</td>
                <td> {ele?.Order_product?.price}</td>
                <td>
                  {" "}
                  {ele?.Order_product?.Product_categories[0]?.Category?.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Address :</strong>{" "}
              {orders?.User_address?.address?.name ?? ""}
              {orders?.User_address?.State?.name ?? ""}
              {orders?.User_address?.country?.name ?? ""}{" "}
              {orders?.User_address?.pincode ?? ""}
            </div>
          </Col>
          <Col col={6}>
            <div className="nomination-box">
              <strong>Mobile :</strong> {orders?.User_address?.mobile}
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>PaymentMode :</strong> {`${orders?.payment_mode}`}
            </div>
          </Col>
          <Col col={6}>
            <div className="nomination-box">
              <strong>Status :</strong> {orders?.status}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Sub Total :</strong>{" "}
              {orders?.sub_total ? `₹${orders.sub_total}` : ""}
            </div>
          </Col>
          <Col col={6}>
            <div className="nomination-box">
              <strong>Shipping Charge :</strong>{" "}
              {orders?.shipping_charge ? `₹${orders?.shipping_charge}` : ""}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
        <Col col={12}>
            <div className="nomination-box">
              <strong>Total :</strong>{" "}
              {orders?.total ? `₹${orders?.total}` : ""}
            </div>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" className="cancel_modal" onClick={props?.handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountDetails;
