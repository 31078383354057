import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cart from "../../Services/cart";
import moment from "moment";
import common from "../../Services/common";
import AccountDetails from "./AccountDetails";
import {useNavigate } from "react-router-dom";

const Index = () => {
  const [orders, setOrders] = useState([]);
  const [accountOpen, setACcountOpen] = useState(false);
  const [accountId, setAccountId] = useState("");
  const navigate = useNavigate();

  const AccountModal = (account_id) => {
    setACcountOpen(true);
    setAccountId(account_id);
  };

  const getOrders = () => {
    cart
      .order_list()
      .then((res) => {
        if (res?.data?.status) {
          setOrders(res?.data?.data?.orders);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <main>
      <div className="mb-4 pb-4" />
      <section className="my-account container">
        <h2 className="page-title">Orders</h2>
        <div className="row">
          <div className="col-lg-3">
            <ul className="account-nav">
              <li>
                <Link
                  to="/"
                  className="menu-link menu-link_us-s menu-link_active"
                >
                  Orders
                </Link>
              </li>
              <li>
                <p
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                  className="menu-link menu-link_us-s"
                >
                  Logout
                </p>
              </li>
            </ul>
          </div>
          {orders.length > 0 ? (
            <div className="col-lg-9">
              <div className="page-content my-account__orders-list">
                <table className="orders-table">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Total</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.length > 0 &&
                      orders?.map((ele, i) => (
                        <tr key={`order-key${i}`}>
                          <td>#{ele?.id}</td>
                          <td>
                            {moment(ele?.created_at).format("D MMM,  YYYY")}
                          </td>
                          <td>{ele?.status}</td>
                          <td>{common.FormatAmount(ele?.total)}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => AccountModal(ele?.id)}
                            >
                              VIEW
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <p>There are no product histroy</p>
            </div>
          )}
        </div>
      </section>
      {accountOpen && (
        <AccountDetails
          accountOpen={accountOpen}
          handleClose={() => {
            setACcountOpen();
            setAccountId(null);
          }}
          accountId={accountId}
          //   id={user.id}
        />
      )}
    </main>
  );
};

export default Index;
