import http from "./http";

const product = {
  add: (data) => http.post("/admin/product/create", data),
  add_cart: (data) => http.post("/customer/cart/create", data),
  list: (param) => http.get("/admin/product/list", { params: param }),
  status: (id, status) =>
    http.post(`/admin/product/activate-deactivate?id=${id}&status=${status}`),
  update: (data) => http.post("/admin/product/update", data),
  deleteImage: (data) => http.post("/common/delete-image", data),
  uploadImage: (data) => http.post("/common/upload-image", data),

  categorylist: (param) => http.get("/admin/product/category-list", { params: param }),

  delete: (param) => http.delete(`/admin/product/delete?id=${param}`),
  getOne: (param) => http.get(`/customer/home/product-details?slug=${param}`),

};

export default product;
