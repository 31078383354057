import http from "./http";

const home = {
  list: (param) => http.get("/customer/home", { params: param }),
  search: (param) => http.get("/customer/home/search", { params: param }),
cartCounts: (param) => http.get("/customer/home/cart-count", { params: param }),


};

export default home;
