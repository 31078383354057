import React, { useState, useEffect } from "react";
import product from "../../Services/product";
import { useParams, useLocation, Link } from "react-router-dom";
import common from "../../Services/common";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "bootstrap";
import { Spinner } from "reactstrap";
import DOMPurify from "dompurify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCounts } from "../../common/ReduxApi";



const Index = () => {
  const [fields, setFields] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [errors, setErrors] = useState({});
  const [cartItems,setCartItems] = useState([])
  const location = useLocation();
  const [reFetch, setreFetch] = useState(false);
  const params = new URLSearchParams(location.search);
  const slug = params.get("slug");
  const dispatch = useDispatch()

  const access_token = useSelector((state) => state?.Auth[0]?.access_token);
  const user_id = useSelector((state) => state?.Auth[0]?.user_id);


  var settings = {
    // dots: true,
    autoplay: true,
    pauseOnHover: true,
    draggable: true,
    arrows: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  var setting = {
    // dots: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    draggable: true,
    arrows: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getProduct = async (slug) => {
    let params = slug;
    setLoader(true);
    try {
      const res = await product.getOne(params);
      if (res?.data?.status) {
        await dispatch(getCounts());
        setFields(res.data.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };
  

  const handleCart = async (id) => {
    let params = {
      product_id: id,
      type: "plus",
    };
    setIsloader(true);
    try {
      const res = await product.add_cart(params);
      if (res?.data?.status) {
        await dispatch(getCounts());
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsloader(false);
      } else {
        let errors = {};
        for (let key in res?.data?.message) {
          errors[key] = res?.data?.message[key];
        }
        setErrors(errors);
        setIsloader(false);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      setIsloader(false);
    }
  };
  

  const sanitizedHTML = DOMPurify.sanitize(fields?.product?.description);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Govihub",
          text: fields?.product?.name,
          url: `https://govihubb.com/product-details?slug=${slug}`,
        });
      } else {
        
        console.log('Web Share API not supported');
       
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  }

  useEffect(() => {
    if (slug) {
      getProduct(slug);
    }
  }, []);
  return (
    <main>
      {loader ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          {/* <img src="/assets/images/logo-blue.png" alt="Govihub-logo" /> */}
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />
        </div>
      ):(<> <div className="mb-md-1 pb-md-3" />
      <section className="product-single container">
        <div className="row">
          <div className="col-lg-7">
            <div
              className="product-single__media"
              data-media-type="vertical-thumbnail"
            >
              <div className="product-single__image">
                <div className="swiper-container">
                  <div className="">
                    <Carousel
                      showThumbs={true}
                      showStatus={false}
                      showArrows={false}
                      autoPlay
                      infiniteLoop={false}
                    >
                      {fields?.product?.Product_images?.map((poster, i) => (
                        <div className="" key={i}>
                          <img
                            loading="lazy"
                            // className="h-auto"
                            src={poster?.image}
                            // width="auto"
                            // height={500}
                            style={{ height: "auto", width: "50%" }}
                            alt
                          />
                          <a
                            data-fancybox="gallery"
                            href="/assets/images/products/product_0.jpg"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Zoom"
                          ></a>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="swiper-button-prev">
                    <svg
                      width={7}
                      height={11}
                      viewBox="0 0 7 11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_prev_sm" />
                    </svg>
                  </div>
                  <div className="swiper-button-next">
                    <svg
                      width={7}
                      height={11}
                      viewBox="0 0 7 11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_next_sm" />
                    </svg>
                  </div>
                </div>
              </div>

             
            </div>
          </div>
          <div className="col-lg-5">
            <div className="d-flex justify-content-between mb-4 pb-md-2">
              <div className="breadcrumb mb-0 d-none d-md-block flex-grow-1">
                <a
                  href="#"
                  className="menu-link menu-link_us-s text-uppercase fw-medium"
                >
                  Home
                </a>
                <span className="breadcrumb-separator menu-link fw-medium ps-1 pe-1">
                  /
                </span>
                <a
                  href="#"
                  className="menu-link menu-link_us-s text-uppercase fw-medium"
                >
                  The Shop
                </a>
              </div>
              {/* /.breadcrumb */}

              {/* /.shop-acs */}
            </div>
            <h1 className="product-single__name">
              {fields?.product?.name} &nbsp; <i class="fa-solid fa-square-share-nodes" style={{cursor:"pointer",color:"#3699ffe6"}}  onClick={handleShare}></i>
            </h1>


            <div className="product-single__price">
              <span className="current-price">
                {common?.FormatAmount(fields?.product?.price)}
              </span>
            </div>
            {/* <div className="product-single__short-desc">
              <p>{fields?.product?.name}</p> 
            </div> */}
              <div className="product-single__meta-info">
              <div className="meta-item">
                <label>Categories {" "} : {" "}</label>
                {fields?.product?.Product_categories?.map((ele, i) => (
                  <span key={i}>
                    {ele?.Category?.name}
                    {i < fields.product.Product_categories.length - 1 && ", "}
                  </span>
                ))}
              </div>
            </div>
            <div className="product-single__addtocart">
  {
    parseInt(fields?.product?.available_stock) > 0 ? (
      access_token && user_id ? (
        <button
          onClick={() => handleCart(parseInt(fields?.product?.id))}
          className="btn btn-primary btn-addtocart js-open-aside"
          disabled={isloader}
        >
          {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
          Add to Cart
        </button>
      ) : (
        <Link
          to="/login"
          className="btn btn-primary btn-addtocart js-open-aside"
        >
          Add to Cart
        </Link>
      )
    ) : (
     <p className="text-danger">Product is out of stock</p>
    )
  }
</div>

           
   
          
          </div>
        </div>
        <div className="product-single__details-tab">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link nav-link_underscore active"
                id="tab-description-tab"
                data-bs-toggle="tab"
                href="#tab-description"
                role="tab"
                aria-controls="tab-description"
                aria-selected="true"
              >
                Description
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="tab-description"
              role="tabpanel"
              aria-labelledby="tab-description-tab"
            >
              <div className="product-single__description">
                <h3 className="block-title mb-4">
                  {fields?.product?.sort_description}
                </h3>
                {/* <p dangerouslySetInnerHTML={{ __html: sanitizedHTML }}> */}
                <p
                  className="product_html_description"
                  dangerouslySetInnerHTML={{
                    __html: `<p className="product_html_description">${fields?.product?.description}</p>`,
                  }}
                />
                {/* {fields?.product?.description} */}
                {/* </p> */}
                {/* <div className="row">
                  <div className="col-lg-6">
                    <h3 className="block-title">Why choose product?</h3>
                    <ul className="list text-list">
                      <li>Creat by cotton fibric with soft and smooth</li>
                      <li>
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </li>
                      <li>Downloadable/Digital Products, Virtual Products</li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="block-title">Sample Number List</h3>
                    <ol className="list text-list">
                      <li>Create Store-specific attrittbutes on the fly</li>
                      <li>
                        Simple, Configurable (e.g. size, color, etc.), bundled
                      </li>
                      <li>Downloadable/Digital Products, Virtual Products</li>
                    </ol>
                  </div>
                </div>
                <h3 className="block-title mb-0">Lining</h3>
                <p className="content">100% Polyester, Main: 100% Polyester.</p> */}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab-additional-info"
              role="tabpanel"
              aria-labelledby="tab-additional-info-tab"
            >
              <div className="product-single__addtional-info">
                <div className="item">
                  <label className="h6">Weight</label>
                  <span>1.25 kg</span>
                </div>
                <div className="item">
                  <label className="h6">Dimensions</label>
                  <span>90 x 60 x 90 cm</span>
                </div>
                <div className="item">
                  <label className="h6">Size</label>
                  <span>XS, S, M, L, XL</span>
                </div>
                <div className="item">
                  <label className="h6">Color</label>
                  <span>Black, Orange, White</span>
                </div>
                <div className="item">
                  <label className="h6">Storage</label>
                  <span>Relaxed fit shirt-style dress with a rugged</span>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab-reviews"
              role="tabpanel"
              aria-labelledby="tab-reviews-tab"
            >
              <h2 className="product-single__reviews-title">Reviews</h2>
              <div className="product-single__reviews-list">
                <div className="product-single__reviews-item">
                  <div className="customer-avatar">
                    <img loading="lazy" src="/assets/images/avatar.jpg" alt />
                  </div>
                  <div className="customer-review">
                    <div className="customer-name">
                      <h6>Janice Miller</h6>
                      <div className="reviews-group d-flex">
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                      </div>
                    </div>
                    <div className="review-date">April 06, 2023</div>
                    <div className="review-text">
                      <p>
                        Nam libero tempore, cum soluta nobis est eligendi optio
                        cumque nihil impedit quo minus id quod maxime placeat
                        facere possimus, omnis voluptas assumenda est…
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-single__reviews-item">
                  <div className="customer-avatar">
                    <img loading="lazy" src="/assets/images/avatar.jpg" alt />
                  </div>
                  <div className="customer-review">
                    <div className="customer-name">
                      <h6>Benjam Porter</h6>
                      <div className="reviews-group d-flex">
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                        <svg
                          className="review-star"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use href="#icon_star" />
                        </svg>
                      </div>
                    </div>
                    <div className="review-date">April 06, 2023</div>
                    <div className="review-text">
                      <p>
                        Nam libero tempore, cum soluta nobis est eligendi optio
                        cumque nihil impedit quo minus id quod maxime placeat
                        facere possimus, omnis voluptas assumenda est…
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-single__review-form">
                <form name="customer-review-form">
                  <h5>Be the first to review “Message Cotton T-Shirt”</h5>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                  <div className="select-star-rating">
                    <label>Your rating *</label>
                    <span className="star-rating">
                      <svg
                        className="star-rating__star-icon"
                        width={12}
                        height={12}
                        fill="#ccc"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.1429 5.04687C11.1429 4.84598 10.9286 4.76562 10.7679 4.73884L7.40625 4.25L5.89955 1.20312C5.83929 1.07589 5.72545 0.928571 5.57143 0.928571C5.41741 0.928571 5.30357 1.07589 5.2433 1.20312L3.73661 4.25L0.375 4.73884C0.207589 4.76562 0 4.84598 0 5.04687C0 5.16741 0.0870536 5.28125 0.167411 5.3683L2.60491 7.73884L2.02902 11.0871C2.02232 11.1339 2.01563 11.1741 2.01563 11.221C2.01563 11.3951 2.10268 11.5558 2.29688 11.5558C2.39063 11.5558 2.47768 11.5223 2.56473 11.4754L5.57143 9.89509L8.57813 11.4754C8.65848 11.5223 8.75223 11.5558 8.84598 11.5558C9.04018 11.5558 9.12054 11.3951 9.12054 11.221C9.12054 11.1741 9.12054 11.1339 9.11384 11.0871L8.53795 7.73884L10.9688 5.3683C11.0558 5.28125 11.1429 5.16741 11.1429 5.04687Z" />
                      </svg>
                      <svg
                        className="star-rating__star-icon"
                        width={12}
                        height={12}
                        fill="#ccc"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.1429 5.04687C11.1429 4.84598 10.9286 4.76562 10.7679 4.73884L7.40625 4.25L5.89955 1.20312C5.83929 1.07589 5.72545 0.928571 5.57143 0.928571C5.41741 0.928571 5.30357 1.07589 5.2433 1.20312L3.73661 4.25L0.375 4.73884C0.207589 4.76562 0 4.84598 0 5.04687C0 5.16741 0.0870536 5.28125 0.167411 5.3683L2.60491 7.73884L2.02902 11.0871C2.02232 11.1339 2.01563 11.1741 2.01563 11.221C2.01563 11.3951 2.10268 11.5558 2.29688 11.5558C2.39063 11.5558 2.47768 11.5223 2.56473 11.4754L5.57143 9.89509L8.57813 11.4754C8.65848 11.5223 8.75223 11.5558 8.84598 11.5558C9.04018 11.5558 9.12054 11.3951 9.12054 11.221C9.12054 11.1741 9.12054 11.1339 9.11384 11.0871L8.53795 7.73884L10.9688 5.3683C11.0558 5.28125 11.1429 5.16741 11.1429 5.04687Z" />
                      </svg>
                      <svg
                        className="star-rating__star-icon"
                        width={12}
                        height={12}
                        fill="#ccc"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.1429 5.04687C11.1429 4.84598 10.9286 4.76562 10.7679 4.73884L7.40625 4.25L5.89955 1.20312C5.83929 1.07589 5.72545 0.928571 5.57143 0.928571C5.41741 0.928571 5.30357 1.07589 5.2433 1.20312L3.73661 4.25L0.375 4.73884C0.207589 4.76562 0 4.84598 0 5.04687C0 5.16741 0.0870536 5.28125 0.167411 5.3683L2.60491 7.73884L2.02902 11.0871C2.02232 11.1339 2.01563 11.1741 2.01563 11.221C2.01563 11.3951 2.10268 11.5558 2.29688 11.5558C2.39063 11.5558 2.47768 11.5223 2.56473 11.4754L5.57143 9.89509L8.57813 11.4754C8.65848 11.5223 8.75223 11.5558 8.84598 11.5558C9.04018 11.5558 9.12054 11.3951 9.12054 11.221C9.12054 11.1741 9.12054 11.1339 9.11384 11.0871L8.53795 7.73884L10.9688 5.3683C11.0558 5.28125 11.1429 5.16741 11.1429 5.04687Z" />
                      </svg>
                      <svg
                        className="star-rating__star-icon"
                        width={12}
                        height={12}
                        fill="#ccc"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.1429 5.04687C11.1429 4.84598 10.9286 4.76562 10.7679 4.73884L7.40625 4.25L5.89955 1.20312C5.83929 1.07589 5.72545 0.928571 5.57143 0.928571C5.41741 0.928571 5.30357 1.07589 5.2433 1.20312L3.73661 4.25L0.375 4.73884C0.207589 4.76562 0 4.84598 0 5.04687C0 5.16741 0.0870536 5.28125 0.167411 5.3683L2.60491 7.73884L2.02902 11.0871C2.02232 11.1339 2.01563 11.1741 2.01563 11.221C2.01563 11.3951 2.10268 11.5558 2.29688 11.5558C2.39063 11.5558 2.47768 11.5223 2.56473 11.4754L5.57143 9.89509L8.57813 11.4754C8.65848 11.5223 8.75223 11.5558 8.84598 11.5558C9.04018 11.5558 9.12054 11.3951 9.12054 11.221C9.12054 11.1741 9.12054 11.1339 9.11384 11.0871L8.53795 7.73884L10.9688 5.3683C11.0558 5.28125 11.1429 5.16741 11.1429 5.04687Z" />
                      </svg>
                      <svg
                        className="star-rating__star-icon"
                        width={12}
                        height={12}
                        fill="#ccc"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.1429 5.04687C11.1429 4.84598 10.9286 4.76562 10.7679 4.73884L7.40625 4.25L5.89955 1.20312C5.83929 1.07589 5.72545 0.928571 5.57143 0.928571C5.41741 0.928571 5.30357 1.07589 5.2433 1.20312L3.73661 4.25L0.375 4.73884C0.207589 4.76562 0 4.84598 0 5.04687C0 5.16741 0.0870536 5.28125 0.167411 5.3683L2.60491 7.73884L2.02902 11.0871C2.02232 11.1339 2.01563 11.1741 2.01563 11.221C2.01563 11.3951 2.10268 11.5558 2.29688 11.5558C2.39063 11.5558 2.47768 11.5223 2.56473 11.4754L5.57143 9.89509L8.57813 11.4754C8.65848 11.5223 8.75223 11.5558 8.84598 11.5558C9.04018 11.5558 9.12054 11.3951 9.12054 11.221C9.12054 11.1741 9.12054 11.1339 9.11384 11.0871L8.53795 7.73884L10.9688 5.3683C11.0558 5.28125 11.1429 5.16741 11.1429 5.04687Z" />
                      </svg>
                    </span>
                    <input type="hidden" id="form-input-rating" defaultValue />
                  </div>
                  <div className="mb-4">
                    <textarea
                      id="form-input-review"
                      className="form-control form-control_gray"
                      placeholder="Your Review"
                      cols={30}
                      rows={8}
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-label-fixed mb-4">
                    <label htmlFor="form-input-name" className="form-label">
                      Name *
                    </label>
                    <input
                      id="form-input-name"
                      className="form-control form-control-md form-control_gray"
                    />
                  </div>
                  <div className="form-label-fixed mb-4">
                    <label htmlFor="form-input-email" className="form-label">
                      Email address *
                    </label>
                    <input
                      id="form-input-email"
                      className="form-control form-control-md form-control_gray"
                    />
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input form-check-input_fill"
                      type="checkbox"
                      defaultValue
                      id="remember_checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="remember_checkbox"
                    >
                      Save my name, email, and website in this browser for the
                      next time I comment.
                    </label>
                  </div>
                  <div className="form-action">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="products-carousel container">
        <h2 className="h3 text-uppercase mb-4 pb-xl-2 mb-xl-4">
          Related <strong>Products</strong>
        </h2>
        <div id="related_products" className="position-relative">
          <div className="swiper-container js-swiper-slider">
            <div className="">
              <Slider
                {...settings}
                infinite={fields?.related_products?.length >= 5}
              >
                {fields?.related_products?.map((relate, i) => (
                  <div
                    className="swiper-slide product-card"
                    key={`key-relate-product-${i}`}
                  >
                    <a href={`/product-details?slug=${relate.slug}`}>
                      <div className="pc__img-wrapper">
                        <a href={`/product-details?slug=${relate.slug}`}>
                          <img
                            loading="lazy"
                            src={relate?.Product_images?.[0]?.image}
                            width={330}
                            height={400}
                            alt="Cropped Faux leather Jacket"
                            className="pc__img"
                          />
                        </a>
                        {/* <button
                          className="pc__atc btn anim_appear-bottom btn position-absolute border-0 text-uppercase fw-medium js-add-cart js-open-aside"
                          data-aside="cartDrawer"
                          title="Add To Cart"
                        >
                          Add To Cart
                        </button> */}
                      </div>
                      <div className="pc__info position-relative">
                        <p className="pc__category">
                          {" "}
                          {relate?.name}
                        </p>
                        <h6 className="pc__title">
                          {/* <a href="./product1_simple.html">Kirby T-Shirt</a> */}
                        </h6>
                        <div className="product-card__price d-flex">
                          <span className="money price">
                            {common?.FormatAmount(relate?.price)}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
            {/* /.swiper-wrapper */}
          </div>
          {/* /.swiper-container js-swiper-slider */}
          <div className="products-carousel__prev position-absolute top-50 d-flex align-items-center justify-content-center">
            <svg
              width={25}
              height={25}
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use href="#icon_prev_md" />
            </svg>
          </div>
          {/* /.products-carousel__prev */}
          <div className="products-carousel__next position-absolute top-50 d-flex align-items-center justify-content-center">
            <svg
              width={25}
              height={25}
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use href="#icon_next_md" />
            </svg>
          </div>
          {/* /.products-carousel__next */}
          <div className="products-pagination mt-4 mb-5 d-flex align-items-center justify-content-center" />
          {/* /.products-pagination */}
        </div>
        {/* /.position-relative */}
      </section></>)}
     
      {/* /.products-carousel container */}
    </main>
  );
};

export default Index;
