import http from "./http";

const cart = {
  add: (data) => http.post("/admin/category/create", data),
  order_add: (data) => http.post("/customer/order/payment-create", data),
  number_add: (data) => http.post("/customer/cart/create", data),
  add_order: (data) => http.post("/customer/order/create", data),
  add_coupon: (data) => http.post("/customer/cart/list", data),
  delete: (data) => http.post("/customer/cart/remove", data),
  state_list: (param) => http.get("/common/state-list", { params: param }),
  country_list: (param) => http.get("/common/country-list", { params: param }),
  list: (data) => http.post("/customer/cart/list", data),
  order_list: (param) => http.get("/customer/order/list", { params: param }),
  getOne: (param) => http.get(`/customer/order/view?id=${param}`),


};

export default cart;

