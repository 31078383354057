import http from "./http";

const user = {
  login: (data) => http.post("/customer/auth/login", data),
  signup: (data) => http.post("/customer/auth/signup", data),
  update: (data) => http.post("/admin/category/update", data),
  deleteImage: (data) => http.post("/common/delete-image", data),
  uploadImage: (data) => http.post("/common/upload-image", data),

  list: (param) => http.get("/admin/category/list", { params: param }),
  categorylist: (param) =>
    http.get("/admin/prevetted-categories", { params: param }),
  prevttedcategorylist: (param) =>
    http.get("/user/prevetted-categories", { params: param }),
  delete: (param) => http.delete(`/admin/category/delete?id=${param}`),
  getOne: (param) => http.get(`/admin/category/view?id=${param}`),
  getExcel: (param) =>
    http.get("/admin/prevetted-grantees/export", {
      params: param,
      responseType: "blob",
    }),



};

export default user;
