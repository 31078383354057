import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import home from "../../Services/home";
import { Link } from "react-router-dom";
import common from "../../Services/common";
import { Col, Container, Row, Spinner } from "reactstrap";
import { getCounts } from "../../common/ReduxApi";
import { useDispatch } from "react-redux";

const Index = () => {
  const [posters, setPosters] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  var settings = {
    // dots: true,
    // infinite: true,
    autoplay: true,
    pauseOnHover: true,
    draggable: true,
    arrows: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var setting = {
    // dots: true,
    // infinite: true,
    autoplay: true,
    pauseOnHover: true,
    draggable: true,
    arrows: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }, 
      },
    ],
  };

  const getPosters = async () => {
    setLoader(true);

    try {
      const res = await home.list();
      if (res?.data?.status) {
        await dispatch(getCounts());
        setPosters(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPosters();
  }, []);
  return (
    <main>
      {loader ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          {/* <img src="/assets/images/logo-blue.png" alt="Govihub-logo" /> */}
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />
        </div>
      ) : (
        <>
          {" "}
          <div className="">
            <div className="slideshow-boxed-right">
              <section className="swiper-container js-swiper-slider slideshow" style={{height:"auto"}}>
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="overflow-hidden position-relative h-100">
                      <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        autoPlay
                        infiniteLoop={true}
                      >
                        {posters?.banners?.map((ele, i) => (
                          <>
                            <Row>
                              <Col md={12} key={i}>
                                <img src={ele?.image} />
                              </Col>
                              <div className="container px-4 mx-3 mx-lg-4 mx-xl-5 position-absolute start-50 top-50 translate-middle">
                                <h6 className="text_dash text-uppercase fs-base fw-medium animate theme-color">
                                  {ele?.heading}
                                </h6>
                                <h2 className="fs-50 fw-bold mb-2 mb-lg-3 lh-1 text-shadow-white">
                                  {ele?.sub_heading}
                                </h2>
                                <h2 className="fs-3 fw-normal mb-3">
                                  {" "}
                                  {ele?.title}
                                </h2>
        
                              </div>
                            </Row>
                          </>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                  {/* /.slideshow-item */}
                </div>
                {/* /.slideshow-wrapper js-swiper-slider */}
                <div className="container">
                  <div className="slideshow-pagination d-flex align-items-center position-absolute bottom-0 left-50 mb-4" />
                  {/* /.products-pagination */}
                </div>
                {/* /.container */}
              </section>
            </div>
          </div>
          {/* First Slider End*/}
          <div className="mb-3 mb-xl-5 pb-3 pt-1 pb-xl-5" />
          <section className="products-carousel container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between flex-wrap gap-3 mb-3 pb-xl-2 mb-xl-4">
              <h2 className="section-title fw-bold">Top Hot Deals</h2>
              <Link
                className="btn-link btn-link_md default-underline text-uppercase fw-medium top_hot_deals"
                to="/products"
              >
                See All Deals
              </Link>
            </div>
            <div id="product_hot_deals" className="position-relative">
              <div className="swiper-container js-swiper-slider">
                <div className="">
                  <Slider
                    {...settings}
                    infinite={posters?.hot_deal_products?.length >= 5}
                  >
                    {posters?.hot_deal_products?.map((poster, i) => (
                      <div
                        className="swiper-slide product-card product-card_style8"
                        kye={i}
                      >
                        <div className="pc__img-wrapper pc__img-wrapper_wide2">
                          <Link to={`/product-details?slug=${poster.slug}`}>
                            <img
                              loading="lazy"
                              src={poster?.Product_images[0]?.image}
                              // width={52}
                              // height={129}
                              // style={{minHeight:"130px",minWidth:"130px"}}
                              alt
                              className="pc__img"
                            />
                          </Link>
                        </div>
                        {/* /.pc__img-wrapper */}
                        <div className="pc__info position-relative">
                          <p className="pc__category">
                            {poster?.Product_categories[0]?.Category?.name}
                          </p>
                          <h6 className="pc__title">
                            <Link to={`/product-details?slug=${poster.slug}`}>
                              {poster?.name}
                            </Link>
                          </h6>

                          <div className="product-card__price d-flex mb-2">
                            <span className="money price fs-5">
                              {common?.FormatAmount(poster?.price)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* /.swiper-wrapper */}
                {/* </Slider> */}
              </div>
              {/* /.swiper-container js-swiper-slider */}
              <div className="products-carousel__prev navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_prev_md" />
                </svg>
              </div>
              {/* /.products-carousel__prev */}
              <div className="products-carousel__next navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_next_md" />
                </svg>
              </div>
              {/* /.products-carousel__next */}
            </div>
            {/* /.position-relative */}
          </section>
          <section className="products-carousel container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between flex-wrap gap-3 mb-3 pb-xl-2 mb-xl-4">
              <h2 className="section-title fw-bold">Featured</h2>
            </div>
            <div id="product_hot_deals" className="position-relative">
              <div className="swiper-container js-swiper-slider">
                <div className="">
                  <Slider
                    {...settings}
                    infinite={posters?.featured_products?.length >= 5}
                  >
                    {posters?.featured_products?.map((feature, i) => (
                      <div
                        className="swiper-slide product-card product-card_style8"
                        kye={i}
                      >
                        <div className="pc__img-wrapper pc__img-wrapper_wide2">
                          <Link to={`/product-details?slug=${feature.slug}`}>
                            <img
                              loading="lazy"
                              src={feature?.Product_images[0]?.image}
                              width={252}
                              height={229}
                              alt
                              className="pc__img"
                            />
                          </Link>
                        </div>
                        {/* /.pc__img-wrapper */}
                        <div className="pc__info position-relative">
                          <p className="pc__category">
                            {feature?.Product_categories[0]?.Category?.name}
                          </p>
                          <h6 className="pc__title">
                            <Link to={`/product-details?slug=${feature.slug}`}>
                              {feature?.name}
                            </Link>
                          </h6>

                          <div className="product-card__price d-flex mb-2">
                            <span className="money price fs-5">
                              {common?.FormatAmount(feature?.price)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* /.swiper-wrapper */}
                {/* </Slider> */}
              </div>
              {/* /.swiper-container js-swiper-slider */}
              <div className="products-carousel__prev navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_prev_md" />
                </svg>
              </div>
              {/* /.products-carousel__prev */}
              <div className="products-carousel__next navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_next_md" />
                </svg>
              </div>
              {/* /.products-carousel__next */}
            </div>
            {/* /.position-relative */}
          </section>
          <div className="mb-3 mb-xl-5 pb-3 pt-1 pb-xl-5" />
          {/* <section className="grid-banner container mb-3">
            <div className="row">
              <div className="col-lg-4">
                <div className="grid-banner__item position-relative mb-3 border-radius-4">
                  <img
                    loading="lazy"
                    className="w-100 h-auto"
                    src="/assets/images/home/demo11/banner-1.jpg"
                    width={450}
                    height={300}
                    alt
                  />
                  <div className="content_abs content_top content_left content_top-md content_left-md pt-2 px-2">
                    <h3 className="fw-bold mb-1">
                      Likelife Audio &amp; Secure Fit
                    </h3>
                    <p className="mb-2 text-secondary static_text">
                      Engineered for your best workout yet
                    </p>
                  </div>
                 
                </div>
              </div>
             
              <div className="col-lg-4">
                <div className="grid-banner__item position-relative mb-3 border-radius-4">
                  <img
                    loading="lazy"
                    className="w-100 h-auto"
                    src="/assets/images/home/demo11/banner-2.jpg"
                    width={450}
                    height={300}
                    alt
                  />
                  <div className="content_abs content_top content_left content_top-md content_left-md pt-2 px-2">
                    <h3 className="fw-bold mb-1">Big Zomm, No Problem</h3>
                    <p className="mb-2 text-secondary static_text">
                      Up close, in tight, and worry free
                    </p>
                  </div>
                 
                </div>
              </div>
              
              <div className="col-lg-4">
                <div className="grid-banner__item position-relative mb-3 border-radius-4">
                  <img
                    loading="lazy"
                    className="w-100 h-auto"
                    src="/assets/images/home/demo11/banner-3.jpg"
                    width={450}
                    height={300}
                    alt
                  />
                  <div className="content_abs content_top content_left content_top-md content_left-md pt-2 px-2">
                    <h3 className="fw-bold mb-1">Handpicked Electronics</h3>
                    <p className="mb-2 text-secondary static_text">
                      We've done the research so you don't have to
                    </p>
                  </div>
               
                </div>
              </div>
             
            </div>
           
          </section> */}
          <div className="mb-3 mb-xl-5 pb-3 pt-1 pb-xl-5" />
          <section className="category-carousel container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between flex-wrap mb-3 pb-xl-2 mb-xl-4">
              <h2 className="section-title fw-bold">Featured Categories</h2>
            </div>
            <div id="category_carousel" className="position-relative">
              <div className="swiper-container">
                <div className="">
                  <Slider
                    {...setting}
                    infinite={posters?.categories?.length >= 5}
                  >
                    {posters?.categories?.map((cate, i) => (
                      <Link to={`/products?slug=${cate?.slug}`}>
                        <div className="swiper-slide" key={i}>
                          <img
                            loading="lazy"
                            className="w-100 h-auto mb-3"
                            src={cate?.image}
                            width={141}
                            height={141}
                            alt
                          />
                          <div className="text-center">
                            <Link to={`/products?slug=${cate?.slug}`}>
                              {cate?.name}
                            </Link>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </Slider>
                </div>
                {/* /.swiper-wrapper */}
              </div>
              {/* /.swiper-container js-swiper-slider */}
              <div className="slideshow-pagination mt-4 d-flex align-items-center justify-content-center" />
              {/* /.products-pagination */}
            </div>
            {/* /.position-relative */}
          </section>
          <div className="mb-3 pb-3 mb-xl-4 pt-1" />
          <section className="products-carousel container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between flex-wrap gap-3 mb-3 pb-xl-2 mb-xl-4">
              <h2 className="section-title fw-bold">Latest Products</h2>
            </div>
            <div id="product_hot_deals" className="position-relative">
              <div className="swiper-container js-swiper-slider">
                <div className="">
                  <Slider
                    {...settings}
                    infinite={posters?.latest_products?.length >= 5}
                  >
                    {posters?.latest_products?.map((latest, i) => (
                      <div
                        className="swiper-slide product-card product-card_style8"
                        kye={i}
                      >
                        <div className="pc__img-wrapper pc__img-wrapper_wide2">
                          <Link to={`/product-details?slug=${latest.slug}`}>
                            <img
                              loading="lazy"
                              src={latest?.Product_images[0]?.image}
                              width={252}
                              height={229}
                              alt
                              className="pc__img"
                            />
                          </Link>
                        </div>
                        {/* /.pc__img-wrapper */}
                        <div className="pc__info position-relative">
                          <p className="pc__category">
                            {latest?.Product_categories[0]?.Category?.name}
                          </p>
                          <h6 className="pc__title">
                            <Link to={`/product-details?slug=${latest.slug}`}>
                              {latest?.name}
                            </Link>
                          </h6>

                          <div className="product-card__price d-flex mb-2">
                            <span className="money price fs-5">
                              {common?.FormatAmount(latest?.price)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* /.swiper-wrapper */}
                {/* </Slider> */}
              </div>
              {/* /.swiper-container js-swiper-slider */}
              <div className="products-carousel__prev navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_prev_md" />
                </svg>
              </div>
              {/* /.products-carousel__prev */}
              <div className="products-carousel__next navigation-sm bg-grey-eeeeee position-absolute top-50 d-flex align-items-center justify-content-center">
                <svg
                  width={25}
                  height={25}
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon_next_md" />
                </svg>
              </div>
              {/* /.products-carousel__next */}
            </div>
            {/* /.position-relative */}
          </section>
          <div className="products-carousel container">
            <center>
              <img src="/assets/images/qr_code.jpg" style={{height:"450px"}} />
            </center>
          </div>
          <div className="mb-3 mb-xl-5 pb-3 pb-xl-5" />
          
        </>
      )}

      {/* /.service-promotion container */}
    </main>
  );
};

export default Index;
