import React, { useState, useEffect } from "react";
import home from "../../Services/home";
import common from "../../Services/common";
import { useLocation, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Spinner } from "reactstrap";

const Index = () => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const params = new URLSearchParams(location.search);
  const [page, setPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(null);
  // const slug = params.get("slug");
  const category_slug = params.get("category_slug");
  const search = params.get("search");
  const pageNum = params.get("page");

  const getPosters = () => {
    setLoader(true);
    let paramsToSend = {};

    if (category_slug) {
      paramsToSend = {
        category_slug: category_slug,
        page: pageNum,
      };
    } else if (search) {
      paramsToSend = {
        search: search,
        page: pageNum,
      };
    } else {
      paramsToSend = {
        page: page,
      };
    }
    home
      .search(paramsToSend)
      .then((res) => {
        if (res?.data?.status) {
          setProducts((prevProducts) =>
            page === 1 ? res?.data?.data?.products : [...prevProducts, ...res?.data?.data?.products]
          );
          setPage(res?.data?.data?.pagination?.currentPage);
          setTotalProducts(
            Math.ceil(
              res?.data?.data?.pagination?.total /
                res?.data?.data?.pagination?.pageSize
            )
          );
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };
  const handlePageChange = (pageNumber) => {
    setLoader(true);
    setPage(pageNumber);
  };
  useEffect(() => {
    getPosters(page,search,category_slug);
  }, [search, category_slug, page]);
  return (
    <main>
      {loader ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          {/* <img src="/assets/images/logo-blue.png" alt="Govihub-logo" /> */}
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />
        </div>
      ) : (
        <>
          {" "}
          <section>
            <div style={{ borderColor: "#eeeeee" }}>
              <div className="shop-banner position-relative ">
                <div
                  className="background-img background-img_overlay responsive_banner"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <img
                    loading="lazy"
                    src="/assets/images/shop/banner_6.jpg"
                    width={1903}
                    height={420}
                    alt="Pattern"
                    className="slideshow-bg__img object-fit-cover"
                  />
                </div>
                <div className="shop-banner__content container position-absolute start-50 top-50 translate-middle">
                  <h2 className="h1 text-uppercase text-white text-center fw-bold mb-3 mb-xl-4 mb-xl-5">
                    Products
                  </h2>
                </div>
                {/* /.shop-banner__content */}
              </div>
              {/* /.shop-banner position-relative */}
            </div>
          </section>
          <div className="mb-4 pb-lg-3" />
          <section className="shop-main container">
            <div className="d-flex justify-content-between mb-4 pb-md-2">
              <div className="breadcrumb mb-0 d-none d-md-block flex-grow-1">
                <a
                  href="#"
                  className="menu-link menu-link_us-s text-uppercase fw-medium"
                >
                  Home
                </a>
                <span className="breadcrumb-separator menu-link fw-medium ps-1 pe-1">
                  /
                </span>
                <a
                  href="#"
                  className="menu-link menu-link_us-s text-uppercase fw-medium"
                >
                  The Shop
                </a>
              </div>
            </div>
            {/* /.d-flex justify-content-between */}
            <div
              className="products-grid row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
              // id="products-grid"
            >
              {products && products.length > 0 ? (
                products&& products?.map((product, i) => (
                  <div className="product-card-wrapper" key={`product-${i}`}>
                    <div className="product-card mb-3 mb-md-4 mb-xxl-5">
                      <div className="pc__img-wrapper">
                        <div
                          className="background-img js-swiper-slider"
                          data-settings='{"resizeObserver": true}'
                        >
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <Link
                                to={`/product-details?slug=${product.slug}`}
                              >
                                <img
                                  loading="lazy"
                                  src={product?.Product_images[0]?.image}
                                  width={258}
                                  height={313}
                                  alt="Cropped Faux leather Jacket"
                                  className="pc__img"
                                />
                              </Link>
                            </div>
                            {/* /.pc__img-wrapper */}
                            {/* <div className="swiper-slide">
                      <Link to="/product-details">
                        <img
                          loading="lazy"
                          src="/assets/images/products/product_1-1.jpg"
                          width={258}
                          height={313}
                          alt="Cropped Faux leather Jacket"
                          className="pc__img"
                        />
                      </Link>
                    </div> */}
                            {/* /.pc__img-wrapper */}
                          </div>
                          <span className="pc__img-prev">
                            <svg
                              width={7}
                              height={11}
                              viewBox="0 0 7 11"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <use href="#icon_prev_sm" />
                            </svg>
                          </span>
                          <span className="pc__img-next">
                            <svg
                              width={7}
                              height={11}
                              viewBox="0 0 7 11"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <use href="#icon_next_sm" />
                            </svg>
                          </span>
                        </div>
                        {/* <button
                          className="pc__atc btn anim_appear-bottom btn position-absolute border-0 text-uppercase fw-medium js-add-cart js-open-aside"
                          data-aside="cartDrawer"
                          title="Add To Cart"
                        >
                          Add To Cart
                        </button> */}
                      </div>
                      <div className="pc__info position-relative">
                        <p className="pc__category">
                          {product?.Product_categories[0]?.Category?.name}
                        </p>
                        <h6 className="pc__title">
                          <Link to={`/product-details?slug=${product.slug}`}>
                            {product?.name}
                          </Link>
                        </h6>
                        <div className="product-card__price d-flex">
                          <span className="money price">
                            {common?.FormatAmount(product?.price)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <center>
                  <p>No Products Found</p>
                </center>
              )}
            </div>
            {/* /.products-grid row */}

            {/* <p className="">SHOWING 36 of 497 items</p> */}
            {totalProducts > 1 && page < totalProducts && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handlePageChange(page + 1)}
                >
                  Show More
                </span>
              </div>
            )}
          </section>
        </>
      )}

      {/* /.shop-main container */}
    </main>
  );
};

export default Index;
