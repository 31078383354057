import React from "react";

const Index = () => {
  return (
   
    <div className="container text-center">
    <div style={{ minHeight: "250px", margin: "0 auto"}}>
      <h2>Contact Us</h2>
      <p className="m-0 mt-5">
        <strong className="fw-medium">
          <i className="fa-solid fa-envelope me-3"></i>Govihubb@gmail.com
        </strong>
      </p>
      <p>
        <strong className="fw-medium">
          <i className="fa-solid fa-phone me-3"></i>+91 837799-5000
        </strong>
      </p>
    </div>
  </div>
  

  );
};

export default Index;
